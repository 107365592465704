/**
 * Grazzee Model: Entity
 *
 * @format
 * @flow strict-local
 */

import _ from 'lodash'
import {client} from '../utils/api-client'
// eslint-disable-next-line import/no-cycle
import Employee from './Employee'
// eslint-disable-next-line import/no-cycle
import EntityType from './EntityType'

export default class Entity {
  id: string

  name: string

  slug: ?string

  logoUrl: string

  approved: boolean

  mainImageUrl: string

  searchableAddress: string

  employees: Array<Employee>

  titles: Array<{name: string, id: string}>

  info: ?{answer: string}

  categories: Array<{
    name: string,
    id: string,
    employees?: {[string]: Employee},
  }>

  entityType: EntityType;
  fee: number;
  ccFee: number;
  ccFeeAdd: number;
  private_mode: boolean;
  group_search_mode: boolean;
  search_on_submit: boolean;
  show_on_search: boolean;
  precision_group_search: boolean;
  empty_search_text: string;
  category_config: {[string]: {[string]: [string]}};

  constructor(data: Object) {
    this.id = data?.id
    this.name = data?.name
    this.slug = data?.slug
    this.logoUrl = data?.logo_url
    this.mainImageUrl = data?.main_image_url
    this.searchableAddress = data?.searchable_address
    this.fee = data?.fee
    this.ccFee = data?.cc_fee
    this.ccFeeAdd = data?.cc_fee_add
    this.approved = data.approved
    this.private_mode = data?.private_mode
    this.group_search_mode = data?.group_search_mode
    this.search_on_submit = data?.search_on_submit
    this.show_on_search = data?.show_on_search
    this.precision_group_search = data?.precision_group_search
    this.empty_search_text = data?.empty_search_text
    this.custom_categories = data?.custom_categories
    let catConfig = {};
    if (data?.category_config != null && data?.category_config?.config != null) {
      if (typeof data?.category_config?.config === 'string' || data?.category_config?.config instanceof String) {
        catConfig = JSON.parse(data?.category_config?.config);
      }
    }
    this.category_config = catConfig

    if (data?.employee_entities != null && data.employee_entities.length > 0) {
      this.employees = data.employee_entities.map(e => new Employee(e))
    }
    if (data?.categories != null && data.categories.length > 0) {
      this.categories = data.categories
    }
    if (data?.titles != null && data.titles.length > 0) {
      this.titles = data.titles
    }
    if (data?.entity_type != null) {
      this.entityType = new EntityType(data.entity_type)
    }
    if (data?.info != null) {
      this.info = data.info
    }
  }

  static async getEntityByCode(code: string): Promise<Entity | boolean> {
    try {
      const r = await client(`entities/by_code/${code}.json`)
      if (r != null) {
        return new Entity(r)
      }
      return false
    } catch (e) {
      console.error(e)
      return false
    }
  }

  static async getEntity(id: string): Promise<Entity | boolean> {
    try {
      const r = await client(`entities/${id}.json`)
      const ent = new Entity(r)
      return ent
    } catch (e) {
      console.error(e)
      return false
    }
  }

  async submitAnswer(answer: string): Promise<Entity | boolean> {
    try {
      await client(`entities/${this.id ?? this.slug}/add-patron.json`, {
        method: 'POST',
        body: {
          info: {
            answer,
          },
        },
      })
      this.info = {answer}
      return this
    } catch (e) {
      console.error(e)
      return false
    }
  }

  isEmployee(user_id: string): boolean {
    return _.findIndex(this.employees, val => val.user_id === user_id) > -1
  }

  findEmployee(id: string): boolean {
    const [emp] = _.filter(this.employees, val => val.id === id)
    return emp;
  }
}
